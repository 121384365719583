<template>
  <div>
    <div class="card">
      <h3 class="title">Order {{ order.id }}</h3>
      <hr />
      <h6>Articles</h6>
      <span v-for="article in JSON.parse(order.articles)" :key="article.id">
        <span v-if="article.sku_client == undefined"
          >{{ article.sku }} - {{ article.name }} - {{ article.quantity }} uni -
          {{ article.price }} AED</span
        >
        <span v-else>
          {{ article.sku_client }} -
          <b-link @click="routeToProduct(article.sku_client)">
            {{ article.name }}
          </b-link>
          - {{ article.quantity }} uni - {{ article.price }} AED<br />
        </span>
      </span>
      <hr />
      <div class="row">
        <div class="col">
          <h4>Status:</h4>
          <span v-if="order.status == 'processing'" class="processing">
            Processing
          </span>
          <span v-else-if="order.status == 'completed'" class="completed">
            Completed
          </span>
          <span v-else-if="order.status == 'cancelled'" class="cancelled">
            Canceled
          </span>
          <span v-else class="otherStatus">
            {{ order.status }}
          </span>
        </div>
        <div class="col">
          <h4>Shipping Company:</h4>

          {{ order.delivery_company }}
          <span
            v-if="
              order.delivery_company == null || order.delivery_company == ''
            "
          >
            <h3>-</h3></span
          >
          <span v-else
            ><h3>{{ order.delivery_company }}</h3></span
          >
        </div>
        <div class="col">
          <h4>Tracking:</h4>
          <span v-if="order.tracking_id == null || order.tracking_id == ''">
            <h3>-</h3></span
          >
          <span v-else
            ><h3>
              <a @click="openTracking(order.tracking_id)">{{
                order.tracking_id
              }}</a>
            </h3></span
          >
        </div>
        <div class="col">
          <h4>Label</h4>
          <span v-if="order.status != 'completed'">-</span>
          <span v-else>
            <button class="btn btn-primary mb-2" @click="openShippingLabel()">
              Etiqueta
            </button>
          </span>
        </div>
        <div class="col">
          <h4>Invoice:</h4>
          <span v-if="order.id_invoice == null || order.id_invoice == ''"
            >-</span
          >
          <span v-else
            ><button
              class="btn btn-primary mb-2"
              @click="openFile(order.id_invoice)"
            >
              {{ order.id_invoice }}
            </button></span
          >
        </div>
      </div>
    </div>
    <div class="card">
      <table>
        <td><h3 class="title">Tracking Status</h3></td>
        <td>
          <span v-if="order.status == completed">
            <h5 class="title" v-if="trackingStatus.delivered_status == true">
              <b style="background-color: green">Delivered</b>
            </h5>
          </span>
        </td>
      </table>
      <hr />
      <!-- <span v-if="order.status != 'completed'"><h5>Not Available Yet</h5></span>
      <span v-else> -->
        <table>
          <td><b>Send Date:</b> {{ splitDate2(trackingStatus.send_date) }}</td>
          <td>
            <h5 class="title" v-if="trackingStatus.delivered_status == true">
              <b style="background-color: green">Entregue</b>
            </h5>
          </td>
        </table>
        <hr />
        <span
          v-if="trackingStatusMovements.length == 0"
          style="font-size: larger"
          ><b>No Movements</b>
        </span>
        <table v-else>
          <tr>
            <th>Date</th>
            <th>Status</th>
            <th>Status Description</th>
          </tr>
          <tr v-for="status in trackingStatusMovements" :key="status.code">
            <td>{{ splitDate(status.date) }}</td>
            <td>{{ status.event }}</td>
            <td>{{ status.code_des }}</td>
          </tr>
        </table>
      <!-- </span> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: function () {
    return {
      order: [],
      trackingStatusMovements: [],
      trackingStatus: [],
    };
  },
  methods: {
    async getTracking_fun() {
      const id = this.$route.params.id;
      try {
        await this.$store.dispatch("getTracking", {
          order: id,
        });
        this.trackingStatus = this.getTracking;
        this.trackingStatusMovements = this.getTracking.movements;
        console.log(this.trackingStatus);
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async getOrder() {
      const id = this.$route.params.id;
      console.log(id);
      try {
        await this.$store.dispatch("getOrderClient", {
          id: id,
        });
        this.order = this.getOrderId.data;
        if (this.order.status == "completed") {
          this.getTracking_fun();
        }
        console.log(this.order);
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async openTracking(tracking) {
      window.open(
        `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${tracking}`,
        "_blank"
      );
    },
    async openShippingLabel() {
      try {
        await this.$store.dispatch("clientShippingOrder", {
          id: this.order.id,
        });
        window.open(
          "data:application/pdf;base64," + this.getClientOrderShipping.label,
          "_blank"
        );
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async routeToProduct(productId) {
      window.open(
        `https://${window.location.host}/specialRequests/products/${productId}`,
        "_blank"
      );
    },
    splitDate2(date) {
      let newDate = new Date(date);
      console.log(newDate);
      newDate.setDate(newDate.getDate() - 1);
      let day = ("0" + newDate.getDate()).slice(-2);
      let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
      let year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
      // let split = newDate.split("T");
      // return split[0];
    },
    async openFile(id) {
      console.log(id);
      try {
        await this.$store.dispatch("fetchPDFLink", {
          id: id,
        });
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    splitDate(date) {
      let split = date.split("T");
      let split2 = split[1].split(".");
      return split[0] + " " + split2[0];
    },
  },
  created() {
    this.getOrder();
  },
  computed: {
    ...mapGetters(["getTracking"]),
    ...mapGetters(["getOrderId"]),
    ...mapGetters(["getClientOrderShipping"]),
  },
};
</script>

<style scoped>
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 50px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: center;
}
.title {
  text-align: left;
  color: white;
}
.processing {
  background-color: #4ea4f0;
  color: white;
  padding: 5px;
  border-radius: 10px;
}
.completed {
  background-color: #28a541;
  color: white;
  padding: 5px;
  border-radius: 10px;
}
.cancelled {
  background-color: #d33;
  color: white;
  padding: 5px;
  border-radius: 10px;
}
.otherStatus {
  background-color: #2c9f8e;
  color: white;
  padding: 5px;
  border-radius: 10px;
}
</style>
